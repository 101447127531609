import { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { assignInlineVars } from "@vanilla-extract/dynamic";
import { CollectionData } from "data/collections";
import { useWindowSize } from "usehooks-ts";

import { ClockIcon } from "~/components/common/Icons/ClockIcon";
import { DotSeparatorIcon } from "~/components/common/Icons/DotSeparatorIcon";
import { DoubleLinePlayIcon } from "~/components/common/Icons/DoubleLinePlayIcon";
import { InfinityIcon } from "~/components/common/Icons/InfinityIcon";
import { MovieTakeIcon } from "~/components/common/Icons/MovieTakeIcon";
import { ImageWithBlur } from "~/components/common/ImageWithBlur/ImageWithBlur";
import { getResponsiveImageURL } from "~/util/cloudinary-utils";
import { isDescWrapping } from "~/util/stringUtils";

import * as styles from "./CollectionHeader.css";

const CollectionHeader = ({
  collectionData,
  showDescription,
}: {
  collectionData: CollectionData;
  showDescription: boolean;
}) => {
  const [showMoreDesc, setShowMoreDesc] = useState(false);
  const collectionDescRef = useRef<HTMLParagraphElement>(null);
  const screen = useWindowSize();
  const MOBILE_BREAKPOINT = screen.width < 600;
  const MAX_HEIGHT_DESC = 72;
  const [isScrollAvailable, setIsScrollAvailable] = useState(false);
  useEffect(() => {
    setIsScrollAvailable(
      isDescWrapping(collectionDescRef.current, 0) ||
        MAX_HEIGHT_DESC < (collectionDescRef.current?.clientHeight || 0),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionDescRef.current?.clientHeight]);

  return (
    <>
      <div className={styles.yellowBadge}>
        <p className={styles.yellowBadgeTitle}>Studio Collection</p>
      </div>
      <h1 className={styles.collectionTitle}>{collectionData.title}</h1>
      <div className={styles.avatarGroup}>
        {[...collectionData.creatorProfileImages]
          .slice(0, MOBILE_BREAKPOINT ? 8 : 10)
          .filter((value, index, self) => self.indexOf(value) === index) // remove duplicates
          .reverse()
          ?.map((imageUrl: string, index: number) => (
            <div className={styles.avatarGroupImg} key={`avatar_${index}`}>
              <ImageWithBlur
                src={getResponsiveImageURL(imageUrl, "200")}
                alt={"avatar-img"}
                width={80}
                height={80}
                objectFit={"cover"}
                layout={"responsive"}
              />
            </div>
          ))}
      </div>
      <div className={styles.detailsWrap}>
        <span className={styles.detailText}>
          <span className={styles.detailIcon}>
            <MovieTakeIcon />
          </span>
          {collectionData.productsInfoIncluded.length} classes
        </span>
        <DotSeparatorIcon className={styles.detailDot} />
        <span className={styles.detailText}>
          <span className={styles.detailIcon}>
            <DoubleLinePlayIcon />
          </span>
          {collectionData.totalLessons} lessons
        </span>
        <DotSeparatorIcon className={styles.detailDot} />
        <span className={styles.detailText}>
          <span className={styles.detailIcon}>
            <ClockIcon />
          </span>
          {Math.round(collectionData.totalHours)}+ total
          hours
        </span>
        <DotSeparatorIcon className={styles.detailDot} />
        <span className={styles.detailText}>
          <span className={styles.detailIcon}>
            <InfinityIcon />
          </span>
          Lifetime access
        </span>
      </div>
      {showDescription ? (
        <p
          style={assignInlineVars({
            [styles.collectionDescHeight]: `${MAX_HEIGHT_DESC}px`,
          })}
          className={classNames(styles.collectionDesc, showMoreDesc && styles.removeMaxHeight)}
          ref={collectionDescRef}
        >
          {collectionData.description}
          {MOBILE_BREAKPOINT && isScrollAvailable ? (
            <span
              className={classNames(styles.showMoreText, showMoreDesc && styles.removeMaxHeight)}
              onClick={() => setShowMoreDesc(!showMoreDesc)}
            >
              {showMoreDesc ? " " : " ..."}
              <span className={styles.showMoreTextUnderline}>
                Show {showMoreDesc ? "less" : "more"}
              </span>
            </span>
          ) : (
            ""
          )}
        </p>
      ) : null}
    </>
  );
};

export default CollectionHeader;
