import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fcollections%2FCollectionHeader%2FCollectionHeader.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61Wy3LjIBC8%2Byu4bFV8IKWXHYdc9lewQBYJAhaQLWcr%2F74lhCz0iFSp2pvMDNDT0z34mRFiPl8j8HcHAGFGcXxHgAnOBIUFp83bDoCSsktpEUhOyv1WmBAmLghkqgFxrJrHRxs94%2FzjomUtCMwllxqBK9ZPENZHbHmZmb1LkppQDTUmrDYIpN1WzNlFQGZpZRDIqbBUv%2B2%2Bds8dyNiBLKSwsMAV4%2FfxyUnpTnbxm0c8irMhbuyd00n4PQizT4pAnHWoHBcPDqJu0dLGQquxMIXUFQK1UlTn2NAAcLIOWK7jVatw%2F0zRhkG9n%2BEOw10LKtzAGyO2ROB02K6q3aAvTCCQRqoBuLZy%2BAqKTsdS6jX0XhvLijvMpbBU2KG9wKVAwjTNLZMCAS1vUNMr1eG18CytlVV3e7DMaWERSJ2AHhgyh2GisaPfKK9UF1zeECgZIVQMcnQ6BEZyRsCtZJaG4j%2F57T1l%2FqeShnnYlGPLrnQGDk7QHX7M0KIvALhghUCcjA4%2FbphEz0V3jKJVY9g1qSX1utaSq4svTYJqH%2BB%2BGZMipAidhDeqqjasL9atL1crVBsVdlZ01nGNClsUmOw1PoXK3fLR%2BeeNHMXNesn%2Fo6mD9vHZSF53humN6kSlu83u21kKGoVz2jb4prEavxdocF1ea9MKRkk2eQXy0Nv9XfHDt2eO8482%2FXdFCcPgKRxzaaaavds%2FmtArNN9doSs8f4YJC0TH0ShhRnUc%2B%2Fi3ZMeJy%2FgaUKce9WjKJP61CvKypTzYJw6j7fDSr3ii%2BoWvZR6PUTTh8dDz2I7ytrEI9O0N8Bx9lj8mO%2Fzqbg2t04LsVmcD8ts52E%2FCdMbAi79x%2Bs%2FmzGWrklHuaZrbzaBRDvY5y1N%2F267bht207JZpt237HeFth8fbuqJf%2Fa6lhzPghsy4MRZbls8PZ4JZhrlX2D96D%2BwRgwoAAA%3D%3D%22%7D"
export var avatarGroup = 'iddsz93';
export var avatarGroupImg = 'iddsz94';
export var collectionDesc = 'iddsz9a';
export var collectionDescHeight = 'var(--iddsz99)';
export var collectionTitle = 'iddsz92';
export var detailDot = 'iddsz98';
export var detailIcon = 'iddsz97';
export var detailText = 'iddsz96';
export var detailsWrap = 'iddsz95';
export var removeMaxHeight = 'iddsz9d';
export var showMoreText = 'iddsz9b';
export var showMoreTextUnderline = 'iddsz9c';
export var yellowBadge = 'iddsz90';
export var yellowBadgeTitle = 'iddsz91';